import PropTypes from "prop-types"
import React from "react"
import SgLink from "./common/sgLink"

function LearnMoreCard({
  icon,
  title,
  link,
  linkTitle,
  rootClassName,
  iconClassName,
  titleClassName,
  linkClassName,
  slideAnimation
}) {
  return (
    <div data-sal={slideAnimation && `${slideAnimation}`} className={`${rootClassName} flex flex-col`}>
      {title && (
        <div className={titleClassName ? `${titleClassName}` : ""}>{title}</div>
      )}
      <SgLink className={linkClassName ? linkClassName : ""} to={link.to}>
        {linkTitle || link.title}
      </SgLink>

      {icon && (
        <div className={iconClassName ? `${iconClassName}` : "m-2"}>{icon}</div>
      )}
    </div>
  )
}

LearnMoreCard.propTypes = {
  icon: PropTypes.any,
  rootClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  title: PropTypes.any,
  link: PropTypes.object,
}

LearnMoreCard.defaultProps = {
  icon: null,
  rootClassName: "",
  titleClassName: "",
  linkClassName: "",
  iconClassName: "",
  title: "",
  link: {
    title: "",
    to: "/",
  },
}

export default LearnMoreCard
