import React, { Fragment, useEffect, useRef, useState } from "react"
import Slider from "react-slick"

// Import css files
import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"

import "./CarouselStyle.scss"
import PropTypes from "prop-types"

const Carousel = ({
  settings = {},
  afterChange = () => {},
  children,
  index,
}: InferProps<typeof propTypes>) => {
  const slider = useRef()

  useEffect(() => {
    if (!isNaN(index)) {
      slider.current.slickGoTo(index)
    }
  }, [index])

  const defaultSettings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 4.3,
          slidesToScroll: 4,
          dots: true,
        },
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 401,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  }

  return (
    <div className="inline-block w-full">
      <Slider
        ref={sref => (slider.current = sref)}
        {...(settings ? settings : defaultSettings)}
        afterChange={afterChange}
      >
        {children}
      </Slider>
    </div>
  )
}

const propTypes = {
  settings: PropTypes.object,
  afterChange: PropTypes.func,
  children: PropTypes.any,
  index: PropTypes.number,
}

export default Carousel
