import React from "react"
import Page from "../components/common/page"
import Card from "../components/Card"
import PartnerCarousel from "../components/PartnerCarousel"
import LearnMoreCard from "../components/LearnMoreCard"
import HeroSection from "../components/HeroSection"
import ContactUsSection from "../components/ContactUsSection"
import SgLink from "../components/common/sgLink"

import HeroSvg from "./svg/hero"
import MaterialAssemblyIcon from "../assets/icons/index/material-assembly-icon.inline.svg"
import EvaluationMaterialIcon from "../assets/icons/index/evaluation-material-icon.inline.svg"
import VideoProductionIcon from "../assets/icons/index/video-production-icon.inline.svg"

import CardIconItemLowCost from "../images/svg/index/card-items/card-icon-item-low-cost.inline.svg"
import CardIconItemFlexibility from "../images/svg/index/card-items/card-icon-item-flexibility.inline.svg"
import CardIconItemConsistency from "../images/svg/index/card-items/card-icon-item-consistency.inline.svg"
import CardIconItemEngagment from "../images/svg/index/card-items/card-icon-item-engagment.inline.svg"
import CardIconItemSpeed from "../images/svg/index/card-items/card-icon-speed.inline.svg"
import CardIconItemMeasurableImpact from "../images/svg/index/card-items/card-icon-item-measurable-impact.inline.svg"
import CardIconItemWhyVideoLearning from "../images/svg/index/card-items/card-icon-why-video-learning.inline.svg"
import CardIconItemAboutUs from "../images/svg/index/card-items/card-icon-about-us.inline.svg"
import CardIconItemHowWeWorkTogether from "../images/svg/index/card-items/card-icon-how-we-work-together.inline.svg"
import {graphql} from 'gatsby';
import {Trans, useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
import Header from "../components/header"
import Footer from "../components/footer"

const simpleCardItems = [
  {
    icon: <MaterialAssemblyIcon />,
    subtitle: "training_educational_material",
  },
  {
    icon: <VideoProductionIcon />,
    subtitle: "video_production",
  },
  {
    icon: <EvaluationMaterialIcon />,
    subtitle: "development_evaluation_material",
  },
]

const cardItems = [
  {
    icon: <CardIconItemLowCost />,
    title: "card_item_low_cost_title",
    subtitle:"card_item_low_cost_subtitle",
  },
  {
    icon: <CardIconItemFlexibility />,
    title: "card_item_flexibility_title",
    subtitle:"card_item_flexibility_subtitle",
  },
  {
    icon: <CardIconItemConsistency />,
    title: "card_item_consistency_title",
    subtitle:"card_item_consistency_subtitle",
  },
  {
    icon: <CardIconItemEngagment />,
    title: "card_item_engagement_title",
    subtitle:"card_item_engagement_subtitle",
  },
  {
    icon: <CardIconItemSpeed />,
    title: "card_item_speed_title",
    subtitle:"card_item_speed_subtitle",
  },

  {
    icon: <CardIconItemMeasurableImpact />,
    title: "card_item_measurable_tile",
    subtitle: "card_item_measurable_subtile",
  },
]

const learnMoreItems = [
  {
    title: "why_video_learning_question",
    icon: <CardIconItemWhyVideoLearning />,
    link: {
      to: "/why-video-learning",
      title: "learn_more",
    },
  },
  {
    title: "what_we_do",
    icon: <CardIconItemAboutUs />,
    link: {
      to: "/about-us",
      title: "learn_more",
    },
  },
  {
    title: "how_we_work_together",
    icon: <CardIconItemHowWeWorkTogether />,
    link: {
      to: "/work-together",
      title: "learn_more",
    },
  },
]

const IndexPage = () => {
  const { t } = useTranslation();
  const {language} = useI18next()

  console.log(language);
  return (
    <Page title="Home">
      <Header />
      <div className="w-full overflow-x-hidden">
        <HeroSection
          title={
            <>
              <p className="lg:leading-snug text-dark-grey text-3xl lg:text-5xl gothic-bold">
                <span>{t('main_title_1')}</span>
                <span className="text-secondary gothic-bold md:text-2xl lg:text-5xl">
                {t('main_title_2')}
                </span>
              </p>
              <p className="text-left text-2xl mt-6 text-dark-grey">
                {t('subtitle')}
              </p>
            </>
          }
          icon={<HeroSvg />}
        />
        <HomepageSection />
        {/* <div className="flex lg:mx-72 mx-5 flex-row flex-wrap justify-around"> */}
        <div className="grid gap-6 md:gap-1 lg:gap-38 lg:mt-14 sm:grid-flow-col mt-12">

          {simpleCardItems.map((item, i) => (
            <Card
              rootClassName="text-center items-center md:m-2 m-8"
              key={i}
              title={t(item.title)}
              subtitleClassName="font-bold pt-4 text-lg gothic-bold text-dark-grey"
              iconClassName="h-64"
              subtitle={t(item.subtitle)}
              icon={item.icon}
              slideAnimation="flip-up"
            ></Card>
          ))}
        </div>
        <div className="lg:mx-primary m-5 mt-32">
          <PartnerCarousel></PartnerCarousel>
        </div>
        <div className="mt-32 flex flex-col text-center items-center">
          <div
            data-sal="slide-down"
            className="lg:mx-36 mx-5 lg:text-5xl text-3xl lg:leading-snug text-dark-grey"
          >
            <p className="gothic-bold">{t('education_is_better_with_video_learning_1')}</p>
            <p className="text-secondary">{t('education_is_better_with_video_learning_2')}</p>
            <p className="text-lg mt-6">
              {t('education_is_better_with_video_learning_3')}
            </p>
          </div>
          <div className="flex flex-row lg:mx-28 flex-wrap lg:mt-primary justify-center">
            {cardItems.map((item, i) => (
              <Card
                rootClassName="lg:w-1/3 w-full items-center p-8"
                key={i}
                title={t(item.title)}
                titleClassName="text-lg text-secondary gothic-bold"
                subtitleClassName="text-sm mt-2"
                subtitle={t(item.subtitle)}
                icon={item.icon}
                slideAnimation="flip-up"
              />
            ))}
          </div>
          <div className="grid gap-4 md:gap-2 lg:gap-24 lg:mt-14 sm:grid-flow-col">
            {learnMoreItems.map((item, i) => (
              <LearnMoreCard
                key={i}
              rootClassName="rounded-3xl bg-background items-center p-12 lg:p-28"
                iconClassName="mt-14 w-98 h-40 lg:h-80"
                linkClassName="px-4 py-2 rounded-full font-bold bg-secondary text-white"
                titleClassName="my-6 w-full lg:text-3xl text-base font-bold lg:h-20 gothic-bold text-dark-grey"
                title={t(item.title)}
                linkTitle={t(item.link.title)}
                link={item.link}
                icon={item.icon}
                slideAnimation="zoom-in"
              ></LearnMoreCard>
            ))}
          </div>
          <ContactUsSection />
        </div>
      </div>
      <Footer />
    </Page>
  )
}

const HomepageSection = () => {
  const { t } = useTranslation();
  return (
    <div
      className="mt-24 flex flex-row flex-wrap justify-center lg:mb-24 text-center"
      data-sal="slide-down"
    >
      <div className="w-full lg:w-9/12">
        <div className="lg:mx-8 lg:text-3xl text-xl w-full font-bold lg:leading-snug text-dark-grey">
          <p className="gothic-bold">
           {t('producing_videos_for_your_needs_1')}
          </p>
          <p className="gothic-bold">
          {t('producing_videos_for_your_needs_2')}
          </p>
          <p className="text-secondary lg:text-6xl text-4xl gothic-bold mt-5">
          {t('producing_videos_for_your_needs_3')}
          </p>
          <p className="text-secondary lg:text-6xl text-3xl gothic-bold mt-1">
          {t('producing_videos_for_your_needs_4')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index", "common"]}, language: {eq: $language}})  {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;