import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next";
import Carousel from "./Carousel/CarouselContainer"


const partners = {
  beat: (
    <div className="text-center mt-5">
      <StaticImage src="../images/partners/beat.png" loading="eager" alt="Beat" height={50} placeholder="tracedSVG"/>
    </div>
  ),
  american_athens_college: (
    <div className="text-center mt-4">
      <StaticImage src="../images/partners/american_athens_college.png" 
      alt="American athens college" height={80}
      placeholder="tracedSVG" />
    </div>
  ),
  gregorys: (
    <div className="text-center mt-1">
      <StaticImage src="../images/partners/gregorys.png" 
      alt="Gregory's" height={90}
      placeholder="tracedSVG" />
    </div>
  ),
  cosmomed: (
    <div className="text-center mt-3">
      <StaticImage src="../images/partners/cosmomed.png" 
      alt="Cosmomed" height={80}
      placeholder="tracedSVG" />
    </div>
  ),
  cosmote: (
    <div className="text-center mt-5">
      <StaticImage src="../images/partners/cosmote.png" 
      alt="Cosmote" height={60}
      placeholder="tracedSVG" />
    </div>
  ),
  roche: (
    <div className="text-center -mt-4">
      <StaticImage src="../images/partners/roche.png" 
      alt="Roche" height={130}
      placeholder="tracedSVG" />
    </div>
  ),
  symetal: (
    <div className="text-center mt-5">
      <StaticImage src="../images/partners/symetal.png" 
      alt="Symetal" height={60}
      placeholder="tracedSVG" />
    </div>
  ),
  voltera: (
    <div className="text-center mt-4">
      <StaticImage src="../images/partners/voltera.png" 
      alt="Voltera" height={50}
      placeholder="tracedSVG" />
    </div>
  ),
  kafea_terrra: (
    <div className="text-center mt-6">
      <StaticImage src="../images/partners/kafea_terra.png" 
      alt="Kafea Terra" height={40}
      placeholder="tracedSVG" />
    </div>
  ),
  ngb: (
    <div className="text-center">
      <StaticImage src="../images/partners/nbg.png" 
      alt="NBG" height={90}
      placeholder="tracedSVG" />
    </div>
  ),
  pharmathen: (
    <div className="text-center mt-6">
      <StaticImage src="../images/partners/pharmathen.png" 
      alt="pharmathen" height={40}
      placeholder="tracedSVG" />
    </div>
  ),
  uowa: (
    <div className="text-center mt-3">
      <StaticImage src="../images/partners/uowa.png" 
      alt="University of west Attica" height={70}
      placeholder="tracedSVG" />
    </div>
  )
};

const PartnerCarousel = () => {
  const { t } = useTranslation();

  const partnerItems = useMemo(() => Object.values(partners), [])
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    centerMode: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          dots: true,
          centerMode: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
         centerMode: true
        }
      }
    ]
  };
  
  return (
    <div className="bg-background rounded-3xl">
      <p className="text-xl pt-8 mb-3 lg:text-4xl text-dark-grey gothic-bold text-center">
      {t('partners_who_trust_us')}
      </p>

      <div className="w-full py-8">
        <Carousel settings={settings}>
          {partnerItems.map((partner, i) => {
            return <div className="partner-carousel-item" key={i}>{partner}</div>
          })}
        </Carousel>
      </div>
    </div>
  )
}

export default PartnerCarousel
